import React from 'react';
import ModalWrapper from '../ui/ModalWrapper'
import { CheckIcon, TimerIcon } from '../../config/icons'

interface Warning {
    isActive: boolean;
    message: string;
}

interface Props {
    showTimerModal: boolean
    showResultModal: boolean
    warning?: Warning | null
    selectedAnswer: any[]
    handleModal: () => void
    setWarning: (value: Warning|null) => void
}

const QuizModal: React.FC<Props> = ({
    showTimerModal,
    showResultModal,
    warning,
    selectedAnswer,
    handleModal, setWarning
}) => {
    const handleCloseModal = () => {
        setWarning({ isActive: false, message: "" })
    }
    return (
        <>
            {(showTimerModal || showResultModal || warning?.isActive) && (
                <ModalWrapper
                    title={
                        warning?.isActive
                            ? warning.message
                            : showResultModal
                                ? 'Test oxiriga yetib keldingiz!'
                                : 'Sizning vaqtingiz tugadi!'
                    }
                    subtitle={
                        warning?.isActive
                            ? undefined
                            : `Barchasi bo'lib ${selectedAnswer.length} ta savolga javob berdingiz!`
                    }
                    onClick={
                        warning?.isActive
                            ? handleCloseModal
                            : handleModal
                    }
                    icon={
                        warning?.isActive
                            ? undefined
                            : showResultModal
                                ? <CheckIcon />
                                : <TimerIcon />
                    }
                    buttonTitle={
                        warning?.isActive
                            ? "Tushundim"
                            : "Rahmat"
                    }
                />
            )}
        </>
    );
}
export default QuizModal
