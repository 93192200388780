import { ChangeEvent, FC, Fragment, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { device } from '../../../styles/BreakPoints'
import { replaceSrc } from '../../../utils/helpers'

const AnswerStyle = styled.div<{ highlightAnswer: boolean }>`
  font-size: clamp(18px, 4vw, 16px);
  color: ${({ theme }) => theme.colors.secondaryText};
  font-weight: 400;
  border: 1px solid
    ${({ highlightAnswer, theme }) =>
    highlightAnswer ? `${theme.colors.themeColor}` : `${theme.colors.border}`};
  background-color: ${({ highlightAnswer, theme }) =>
    highlightAnswer ? `${theme.colors.selectedAnswer}` : `${theme.colors.answerBg}`};
  border-radius: 16px;
  margin-top: clamp(13px, calc(10px + 6 * ((100vw - 600px) / 1320)), 16px);
  cursor: pointer;
  ${({ highlightAnswer }) =>
    highlightAnswer &&
    css`
      transition: border 0.2s ease-in;
    `}
  @media ${device.md} {
    font-weight: 500;
  }
  input {
    visibility: hidden;
    margin: 0;
  }
`

const AnswerLabel = styled.label`
  padding: 18px;
  display: flex;
  cursor: pointer;
  @media ${device.md} {
    padding: 14px;
  }
`

const TextArea = styled.textarea`
  font-size: clamp(18px, 4vw, 16px);
  color: ${({ theme }) => theme.colors.secondaryText};
  font-weight: 400;
  border: 1px solid ${({ theme }) => `${theme.colors.border}`};
  background-color: ${({ theme }) => `${theme.colors.answerBg}`};
  border-radius: 16px;
  margin-top: clamp(13px, calc(10px + 6 * ((100vw - 600px) / 1320)), 16px);
  padding: 14px;
  cursor: pointer;
  width: 100%;
  @media ${device.md} {
    font-weight: 500;
  }
  &:focus {
    outline-color: ${({ theme }) => theme.colors.secondaryText};
  }
`

const ChoiceLabel = styled.span`
  color:  ${({ theme }) => theme.colors.secondaryText};;
`

interface AnswerProps {
  index: number
  choice: string | undefined
  type: boolean | undefined
  selectedAnswer: { quiz: number, answer: string }[]
  question: string
  id: number | undefined
  onClick: (e: { quiz: number, answer: string }) => void
  option: string
}

const Answer: FC<AnswerProps> = ({ onClick, index, choice, type, selectedAnswer, question, id, option }) => {
  const [area, setArea] = useState('')

  const highlightAnswer = selectedAnswer.some(item => item.quiz === id && item.answer === question);
  const htmlData = `<span style="display: flex; align-items: center; gap: 20px;"><strong style="color: ">${option}.</strong> ${replaceSrc(choice as string)}</span>`

  useEffect(() => {
    setArea("")
  }, [id])

  useEffect(() => {
    if (area) {
      onClick({ quiz: id as number, answer: area as string })
    }
  }, [area])

  useEffect(() => {
    const selected = selectedAnswer.find(item => item.quiz === id);
    if (selected) {
      setArea(selected.answer)
    } else {
      setArea('')
    }
  }, [id, selectedAnswer])

  return (
    <Fragment>
      {type ? <TextArea
        placeholder='Javobingizni yozing'
        rows={5}
        cols={40}
        value={area}
        onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setArea(e.target.value)}
      /> :
        <AnswerStyle key={index} highlightAnswer={highlightAnswer}>
          <AnswerLabel
            onClick={() => onClick({ quiz: id as number, answer: question })}
          >
            <ChoiceLabel
              dangerouslySetInnerHTML={{ __html: htmlData && htmlData as any }}
            />
          </AnswerLabel>
        </AnswerStyle>
      }
    </Fragment>
  )
}
export default Answer