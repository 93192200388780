import { FC } from 'react'
import styled from 'styled-components'

import { device } from '../../styles/BreakPoints'
import { LogoContainer } from '../../styles/Global'
import LogoIcon from './LogoComponent/LogoComponent'
import forbiddenImage from '../../assets/icons/forbiddens.png'

const ResultScreenContainer = styled.div`
  max-width: 900px;
  margin: 60px auto;
  @media ${device.md} {
    width: 90%;
    margin: 30px auto;
    padding-top: 40px;
  }
`

const InnerContainer = styled.div`
  background: ${({ theme }) => theme.colors.cardBackground};
  border-radius: 4px;
  margin: 0 auto;
  margin-bottom: 40px;
  padding: 40px 90px 90px 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media ${device.md} {
    padding: 15px;
  }

   @media(max-width: 750px) {
    /* flex-direction: row;
    gap: 15px; */
  }
`

const QuestionNumber = styled.h6`
  font-size: clamp(16px, 5vw, 24px);
  font-weight: 500;
  line-height: 1.3;
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 20px;
  text-align: center;
  color: #bb2124;

  @media(max-width: 750px) {
    font-size: 16px;
    flex-direction: row;
    text-align: center;
  }
`
const ForbiddenImage = styled.img`
  width: 100px;
  height: 200px;
  object-fit: contain;

  @media(max-width: 750px) {
    width: 50px;
    height: 50px;
    margin-bottom: 30px;
  }
`

const TestForbiddenScreen: FC = () => {

  return (
    <ResultScreenContainer>
      <LogoContainer>
        <LogoIcon />
      </LogoContainer>
      <InnerContainer>
        <ForbiddenImage src={forbiddenImage} alt="forbidden" />
        <QuestionNumber>Sizni ogohlantirdik, Siz testdan chetlatildingiz</QuestionNumber>
      </InnerContainer>
    </ResultScreenContainer>
  )
}

export default TestForbiddenScreen
