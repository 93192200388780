import { PageCenter, CenterCardContainer, LogoContainer } from "../../styles/Global"
import LogoIcon from '../ResultScreen/LogoComponent/LogoComponent'
import { StartIcon } from "../../config/icons"
import Button from "../ui/Button"
import { useState } from 'react'
import styled from 'styled-components'
import { useQuiz } from "../../context/QuizContext"
import { ScreenTypes } from "../../types"
import useEnterKeyPress from "../../hooks/UseEnterKeyPress"
import { api } from "../../api/api"
import toast from "react-hot-toast"
import Loader from '../../hooks/Loader'

const RadioButton = styled.input.attrs({ type: 'radio' })`
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #800080;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  position: relative;

  &:checked::before {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    background-color: #800080;
    border-radius: 50%;
    position: absolute;
    top: 3px;
    left: 3px;
  }

  @media(max-width: 750px){
    width: 22px;
    height: 20px;
  }
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
  gap: 10px;
  width: 100%;
  max-width: 350px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin-bottom: 30px;
`
const Heading = styled.h2`
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  text-align: center;
  max-width: 400px;
`
export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
`
export default function Survey() {
  const [selectedOption, setSelectedOption] = useState('')
  const { setCurrentScreen } = useQuiz()
  const [loader, setLoader] = useState(false)

  const errorNotify = () => toast.error("Hatolik yuz berdi qayta urinib ko'ring!")

  const goToQuestionScreen = () => {
    setLoader(true)
    const isOnline = selectedOption === "yes" ? true : false
    api.createResource('update-study-type/', { study_online: String(isOnline) }).then(data => {
      if(data){
        setCurrentScreen(ScreenTypes.QuestionScreen)
      }
    })
    .catch(err => {
      if(err){
        errorNotify()
      }
    }).finally(() => setLoader(true))
  }

  useEnterKeyPress(goToQuestionScreen, Boolean(selectedOption))
  
  return (
    <PageCenter justifyCenter>
      <CenterCardContainer>
        <LogoContainer>
          <LogoIcon />
        </LogoContainer>
        <Heading>Darslar Andijon shaxrida bo&apos;lib o&apos;tishini xisobga olgan holda, Siz ta&apos;limning qanday shaklida o&apos;qishni istaysiz?</Heading>
        <Container>
          <LabelWrapper>
            <RadioButton
              name="radioGroup"
              value="yes"
              checked={selectedOption === 'yes'}
              onChange={() => setSelectedOption('yes')}
              id="online"
            />
            <Label htmlFor="online">
              Masofadan onlayn tarzda qatnashmoqchiman
            </Label>
          </LabelWrapper>
          <LabelWrapper>
            <RadioButton
              name="radioGroup"
              value="no"
              checked={selectedOption === 'no'}
              onChange={() => setSelectedOption('no')}
              id="offline"
            />
            <Label htmlFor="offline">
              An&apos;anaviy ya&apos;ni o'quv binosiga kelib qatnashmoqchiman
            </Label>
          </LabelWrapper>

        </Container>
        <Button
          text="Testni Boshlash"
          icon={loader ? <Loader /> : <StartIcon />}
          iconPosition="left"
          onClick={goToQuestionScreen}
          disabled={!selectedOption}
          bold
        />
      </CenterCardContainer>
    </PageCenter>
  )

}