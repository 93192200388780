import styled from 'styled-components'

import { useQuiz } from '../../context/QuizContext'
import { device } from '../../styles/BreakPoints'
import {
  CenterCardContainer,
  HighlightedText,
  LogoContainer,
  PageCenter,
} from '../../styles/Global'
import { ScreenTypes } from '../../types'

import Button from '../ui/Button'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import LogoIcon from '../ResultScreen/LogoComponent/LogoComponent'
import { validatePhoneNumber } from '../../hooks/phoneValidation'
import { api } from '../../api/api'
import { removeToken, setToken } from '../../lib/token'
import useEnterKeyPress from '../../hooks/UseEnterKeyPress'
import toast from 'react-hot-toast'

const Heading = styled.h2`
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 20px;
  text-align: center;
`

const PhoneNumberInput = styled.input`
  font-size: 18px;
  font-weight: 600;
  border-radius: 6px;
  border: none;
  height: 50px;
  padding-inline-start: 10px;
  background-color: ${({ theme }) => `${theme.colors.buttonText}`};
  color: ${({ theme }) => `${theme.colors.outlineButtonText}`};
  border: 1px solid ${({ theme }) => `${theme.colors.border}`};
  margin-bottom: 20px;

  &:focus {
    outline: none;
  }
  @media ${device.md} {
    font-size: 16px;
    font-weight: 500;
  }
`

const ErrorMessage = styled.p`
  color: red;
  margin: 10px 0 20px 0;
  text-align: start;
`

const QuizTopicsScreen: React.FC = () => {
  const { setCurrentScreen, selectQuizTopic, setTimer, totalQuestion } = useQuiz()
  const [phoneNumber, setPhoneNumber] = useState('+998')
  const [isValid, setIsValid] = useState<boolean | null>(null)
  const phoneInputRef = useRef<HTMLInputElement | null>(null)

  const errorNotify = (message?: string) => toast.error(message || "Hatolik yuz berdi qayta urinib ko'ring!")

  const goToQuizDetailsScreen = () => {
    removeToken("userToken")
    const modifiedString = phoneNumber.replace(/\+/g, "")
    api.createResource<any>('check-candidate/', { phone: modifiedString }).then(data => {
      if (data) {
        setToken('userToken', data?.data?.token?.access, {})
        selectQuizTopic(data?.data?.user?.direction)
        setTimer(data?.data?.user?.time * 60)
        totalQuestion(data?.data?.user?.quiz_count)
        setCurrentScreen(ScreenTypes.QuizDetailsScreen)
      } else {
        errorNotify()
      }
    }).catch(err => {
      console.log(err)
      if (err) {
        errorNotify(err?.response?.data?.detail)
      }
    })
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value: string = event.target.value as string
    setPhoneNumber(value)
    setIsValid(validatePhoneNumber(value))
  }

  useEnterKeyPress(goToQuizDetailsScreen, Boolean(isValid))

  useEffect(() => {
    phoneInputRef.current?.focus()
  }, [])

  return (
    <PageCenter light justifyCenter>
      <CenterCardContainer>
        <LogoContainer>
          <LogoIcon />
        </LogoContainer>
        <Heading>
          <HighlightedText>Digital Girls</HighlightedText> Kirish!
        </Heading>

        <PhoneNumberInput
          value={phoneNumber}
          placeholder="Telefon Raqamigizni kiriting"
          onChange={handleChange}
          type='tel'
          ref={phoneInputRef}
        />
        {isValid !== null && !isValid &&
          <ErrorMessage>
            Tel raqamingizni to&apos;g&apos;ri formatda kirgazing!
          </ErrorMessage>
        }
        <Button text="Kirish" onClick={goToQuizDetailsScreen} bold disabled={!isValid as boolean} />
      </CenterCardContainer>
    </PageCenter>
  )
}

export default QuizTopicsScreen
