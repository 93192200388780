import { useEffect } from 'react'

const useEnterKeyPress = (callback: () => void, isActive: boolean) => {
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Enter' && isActive) {
                callback()
            }
        }

        window.addEventListener('keydown', handleKeyDown)

        return () => {
            window.removeEventListener('keydown', handleKeyDown)
        }
    }, [callback, isActive])
}
export default useEnterKeyPress
