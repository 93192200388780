import { FC, Fragment, ReactNode } from 'react'
import styled from 'styled-components'
import { device } from '../../../styles/BreakPoints'
import Answer from '../Answer'
import Button from '../../ui/Button'
import { Next, Left } from '../../../config/icons'
import { replaceSrc } from '../../../utils/helpers'
import { memo } from 'react'
const QuestionContainer = styled.div`
  margin-top: 30px;
  margin-bottom: 40px;
  max-width: 88%;
  @media ${device.sm} {
    max-width: 100%;
  }
`

const AnswersContainer = styled.div`
  max-width: 78%;
  @media ${device.sm} {
    max-width: 100%;
  }
`

const QuestionStyle = styled.div`
  font-size: clamp(18px, 4vw, 28px);
  font-weight: 500;
  margin-bottom: 25px;
  color: ${({ theme }) => theme.colors.primaryText};
  line-height: 1.3;
`

interface QuestionTypes {
  question: string | undefined
  option1: string | undefined
  option2: string | undefined
  option3: string | undefined
  option4: string | undefined
  disabled?: boolean
  is_free_option: boolean | undefined
  icon?: ReactNode
  id: number | undefined
  activeQuestion: number
  iconPosition?: 'left' | 'right'
  selectedAnswer: { quiz: number, answer: string }[]
  handleAnswerSelection: (values: { quiz: number, answer: string }) => void
  onClick?: (event: { quiz: number, answer: string }) => void
  onClickNext: () => void
  handleBack: () => void
}

const ButtonWrapper = styled.div`
  position: absolute;
  right: 60px;
  bottom: 30px;
  display: flex;
  gap: 20px;
  @media ${device.sm} {
    justify-content: flex-end;
    width: 90%;
    right: 15px;
  }
`

const Question: FC<QuestionTypes> = ({
  question,
  option1,
  option2,
  option3,
  option4,
  is_free_option,
  selectedAnswer,
  handleAnswerSelection,
  id, activeQuestion,
  onClickNext, handleBack
}) => {
  return (
    <QuestionContainer>
      <QuestionStyle dangerouslySetInnerHTML={{ __html: replaceSrc(question as string) }} />
      <AnswersContainer>
        {is_free_option ? (
          <Answer
            choice={option1}
            index={1}
            key={1}
            onClick={handleAnswerSelection}
            type={is_free_option}
            selectedAnswer={selectedAnswer}
            question=""
            id={id}
            option="A"
          />
        ) : (
          <Fragment>
            <Answer
              choice={option1}
              index={1}
              key={1}
              onClick={handleAnswerSelection}
              type={is_free_option}
              selectedAnswer={selectedAnswer}
              question="option1"
              id={id}
              option="A"
            />
            <Answer
              choice={option2}
              index={1}
              key={1}
              onClick={handleAnswerSelection}
              type={is_free_option}
              selectedAnswer={selectedAnswer}
              question="option2"
              id={id}
              option="B"
            />
            <Answer
              choice={option3}
              index={1}
              key={1}
              onClick={handleAnswerSelection}
              type={is_free_option}
              selectedAnswer={selectedAnswer}
              question="option3"
              id={id}
              option="C"
            />
            <Answer
              choice={option4}
              index={1}
              key={1}
              onClick={handleAnswerSelection}
              type={is_free_option}
              selectedAnswer={selectedAnswer}
              question="option4"
              id={id}
              option="D"
            />
          </Fragment>
        )}
      </AnswersContainer>
      <ButtonWrapper>
        <Button
          text="Orqaga"
          onClick={handleBack}
          icon={<Left />}
          iconPosition="left"
          disabled={activeQuestion <= 0}
        />
        <Button
          text={question && activeQuestion === 19 ? 'Tamomlash' : 'Keyingisi'}
          onClick={onClickNext}
          icon={<Next />}
          iconPosition="right"
          disabled={selectedAnswer.length === 0}
        />
      </ButtonWrapper>
    </QuestionContainer>
  )
}
const MemorizedQuestion = memo(Question)
export default MemorizedQuestion
