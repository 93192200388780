import styled from 'styled-components'

import { StartIcon } from '../../config/icons'
import { useQuiz } from '../../context/QuizContext'
import {
  CenterCardContainer,
  HighlightedText,
  LogoContainer,
  PageCenter,
} from '../../styles/Global'
import { ScreenTypes } from '../../types'
import { convertSeconds } from '../../utils/helpers'

import Button from '../ui/Button'
import LogoIcon from '../ResultScreen/LogoComponent/LogoComponent'
import { GetMethod } from '../../api/getMethod'
import { useState } from 'react'
import warningSvg from '../../assets/icons/warning.svg'
import toast from 'react-hot-toast'
import useEnterKeyPress from '../../hooks/UseEnterKeyPress'

const AppTitle = styled.h2`
  font-weight: 700;
  font-size: 32px;
  color: ${({ theme }) => theme.colors.themeColor};
`

const DetailTextContainer = styled.div`
  font-size: 20px;
  font-weight: 500;
  margin-top: 15px;
  margin-bottom: 40px;
  text-align: center;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const DetailText = styled.p<{ strict?: boolean }>`
  font-size: 20px;
  font-weight: 500;
  margin-top: 15px;
  line-height: 1.3;
  text-align: start;
  width: 100%;
  color: ${({ strict }) => strict && "#d39e00"};
  display: flex;
`

export const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  padding-left: 35px; 
  user-select: none; 
  margin-top: 30px;
  font-size: 18px;
  font-weight: 400;
`;

export const CheckboxInput = styled.input.attrs({ type: 'checkbox' })`
  display: none; 
`;

export const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee; 
  border-radius: 4px; 
  transition: background-color 0.3s, border 0.3s;
  
  ${CheckboxInput}:checked + & {
    background-color: #2196F3;
  }
  
  ${CheckboxInput}:checked + &::after {
    content: "";
    position: absolute;
    left: 7.7px;
    top: 4px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`;
export const WarningIcon = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 8px;
  margin-top: 5px;
`

const QuizDetailsScreen = () => {
  const { setCurrentScreen, quizDetails, setQuestions } = useQuiz()

  const { selectedQuizTopic, totalQuestions, totalScore, totalTime } = quizDetails
  const [checked, setChecked] = useState(false)

  const { data, error } = GetMethod<any>('get-quiz/')
  const errorNotify = () => toast.error("Hatolik yuz berdi qayta urinib ko'ring!")

  const gotToNextPage = () => {
    if (data && !error) {
      setQuestions(data?.data)
      setCurrentScreen(ScreenTypes.SurveyScreen)
    }

    if (!data || error){
      errorNotify()
    }
  }

  useEnterKeyPress(gotToNextPage, Boolean(checked))

  return (
    <PageCenter light justifyCenter>
      <CenterCardContainer>
        <LogoContainer>
          <LogoIcon />
        </LogoContainer>
        <AppTitle>DIGITAL GIRLS</AppTitle>
        <DetailTextContainer>
          <DetailText>
            Tanlangan Test: <HighlightedText>{selectedQuizTopic}</HighlightedText>
          </DetailText>
          <DetailText>
            Jami savollar soni: <HighlightedText>{totalQuestions}</HighlightedText> ta
          </DetailText>
          <DetailText>
            Umumiy to'planadigon ball: <HighlightedText>{totalScore}</HighlightedText> %
          </DetailText>
          <DetailText>
            Umumiy vaqt: <HighlightedText>{convertSeconds(totalTime)}</HighlightedText>
          </DetailText>
          <DetailText>
            Bilmagan savollarni o&apos;tkazib yuborishingiz mumkin,
            o&apos;tkazib yuborilgan savollarga orqaga qaytish tugmasini bosib, qayta javob bersangiz bo'ladi.
          </DetailText>
          <DetailText strict>
            <WarningIcon src={warningSvg} alt="warning" />
            Brauzerni boshqa sahifaga almashtirmang yoki chiqmang, Ikki marta ogohlantirilgandan so&apos;ng, testdan chetlatilasiz.
          </DetailText>
          <CheckboxLabel>
            <CheckboxInput onChange={(e) => setChecked(e.target.checked)} />
            <Checkmark />
            Shartlarga Roziman
          </CheckboxLabel>
        </DetailTextContainer>
        <Button
          text="Keyingisi"
          icon={<StartIcon />}
          iconPosition="left"
          onClick={gotToNextPage}
          disabled={!checked}
          bold
        />
      </CenterCardContainer>
    </PageCenter>
  )
}
export default QuizDetailsScreen
