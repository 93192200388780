import { FC, useEffect, useState } from 'react'
import styled from 'styled-components'

import { useQuiz } from '../../context/QuizContext'
import { useTimer } from '../../hooks'
import { device } from '../../styles/BreakPoints'
import { PageCenter } from '../../styles/Global'

import Question from './Question'
import QuizHeader from './QuizHeader'
import LogoIcon from '../ResultScreen/LogoComponent/LogoComponent'
import { refreshPage } from '../../utils/helpers'
import { api } from '../../api/api'
import WarningComponent from '../../utils/warningComponent'
import QuizModal from './QuziButton'
import useEnterKeyPress from '../../hooks/UseEnterKeyPress'

const QuizContainer = styled.div<{ selectedAnswer: boolean }>`
  width: 900px;
  min-height: 500px;
  background: ${({ theme }) => theme.colors.cardBackground};
  border-radius: 4px;
  padding: 30px 60px 80px 60px;
  margin-bottom: 70px;
  position: relative;
  @media ${device.md} {
    width: 100%;
    padding: 15px 15px 80px 15px;
  }
  button {
    span {
      svg {
        path {
          fill: ${({ selectedAnswer, theme }) =>
    selectedAnswer ? `${theme.colors.buttonText}` : `${theme.colors.darkGray}`};
        }
      }
    }
  }
`

const LogoContainer = styled.div`
  margin-top: 50px;
  margin-bottom: 50px;
  @media ${device.md} {
    margin-top: 10px;
    margin-bottom: 20px;
    svg {
      width: 185px;
      height: 80px;
    }
  }
`

interface warningType {
  message: string
  isActive: boolean
}
const QuestionScreen: FC = () => {
  const [activeQuestion, setActiveQuestion] = useState<number>(0)
  const [selectedAnswer, setSelectedAnswer] = useState<{ quiz: number, answer: string }[]>([])
  const [showTimerModal, setShowTimerModal] = useState<boolean>(false)
  const [showResultModal, setShowResultModal] = useState<boolean>(false)
  const [warning, setWarning] = useState<null | warningType>(null)

  const {
    questions,
    quizDetails,
    timer,
    setTimer,
    setEndTime,
  } = useQuiz()

  const currentQuestion = questions && questions[activeQuestion]

  useEffect(() => {
    if (showTimerModal) {
      document.body.style.overflow = 'hidden'
      api.createResource("answer-the-quiz/", selectedAnswer).then(data => console.log(data))
        .catch(err => console.log(err))
    }
  }, [showTimerModal, selectedAnswer])

  const onClickNext = () => {
    if (questions && activeQuestion !== questions.length - 1) {
      setActiveQuestion(activeQuestion + 1)
    } else {
      const timeTaken = quizDetails.totalTime - timer
      setEndTime(timeTaken)
      setShowResultModal(true)
    }
    if (activeQuestion === quizDetails.totalQuestions - 1) {
      api.createResource("answer-the-quiz/", selectedAnswer).then(data => console.log(data))
        .catch(err => console.log(err))
    }
  }
  const handleAnswerSelection = (values: { quiz: number, answer: string }) => {
    setSelectedAnswer((prev: { quiz: number, answer: string }[]) => {
      const updatedAnswers = prev.map(item =>
        item.quiz === values.quiz ? { ...item, answer: values.answer } : item
      )
      const isAnswerUpdated = prev.some(item => item.quiz === values.quiz)
      return isAnswerUpdated ? updatedAnswers : [...prev, values]
    })
  }

  const handleBack = () => {
    if (questions && activeQuestion !== questions.length) {
      setActiveQuestion(activeQuestion - 1)
    }
  }

  const handleModal = () => {
    refreshPage()
  }
  useTimer(timer * 1000, quizDetails, setEndTime, setTimer, setShowTimerModal, showResultModal)

  return (
    <WarningComponent setWarning={setWarning}>
      <PageCenter>
        <LogoContainer>
          <LogoIcon />
        </LogoContainer>
        <QuizContainer selectedAnswer={selectedAnswer.length > 0}>
          <QuizHeader
            activeQuestion={activeQuestion}
            totalQuestions={quizDetails.totalQuestions}
            timer={timer}
          />
          <Question
            question={currentQuestion?.question}
            option1={currentQuestion?.option1}
            option2={currentQuestion?.option2}
            option3={currentQuestion?.option3}
            option4={currentQuestion?.option4}
            id={currentQuestion?.id}
            is_free_option={currentQuestion?.is_free_option}
            handleAnswerSelection={handleAnswerSelection}
            selectedAnswer={selectedAnswer}
            activeQuestion={activeQuestion}
            onClickNext={onClickNext}
            handleBack={handleBack}
          />
        </QuizContainer>
        <QuizModal 
          showTimerModal={showTimerModal}
          showResultModal={showResultModal}
          warning={warning}
          selectedAnswer={selectedAnswer}
          handleModal={handleModal}
          setWarning={setWarning}
        />
      </PageCenter>
    </WarningComponent>
  )
}

export default QuestionScreen
