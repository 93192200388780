import { ClipLoader } from "react-spinners"

export default function Loader() {
    return (
        <ClipLoader
            size={23}
            color="#fff"
            loading={true}
        />
    )
}