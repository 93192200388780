import React, { Fragment, memo, ReactNode, useCallback, useEffect, useState } from 'react'
import { useQuiz } from '../context/QuizContext'
import { ScreenTypes } from '../types'
import { setToken } from '../lib/token'
import { api } from '../api/api'

interface warningType {
    message: string
    isActive: boolean
}

interface WarningInterface {
    children: ReactNode
    setWarning: (value: warningType | null) => void
}

const WarningComponent: React.FC<WarningInterface> = ({ children, setWarning }) => {
    const [warningCount, setWarningCount] = useState<number>(0)
    const { setCurrentScreen } = useQuiz()

    const handleBlur = useCallback(() => {
        setWarningCount(prevCount => {
            const newCount = prevCount + 1
            if (newCount >= 3) {
                setToken("forbidden", "forbidden user", {})
                setWarning({ isActive: true, message: "Sizni ogohlantirdik, Siz testdan Chetlatildingiz" })
                api.createResource("block-candidate/", { is_blocked: "true" }).then(data => {
                    console.log(data)
                }).catch(err => console.log(err))
                setCurrentScreen(ScreenTypes.ResultScreen)
            } else {
                setWarning({ isActive: true, message: "Iltimos Testdan Chiqmang" })
            }
            return newCount
        })
    }, [setCurrentScreen, setWarning])

    useEffect(() => {
        window.addEventListener('blur', handleBlur)

        return () => {
            window.removeEventListener('blur', handleBlur)
        }
    }, [handleBlur])

    return (
        <Fragment>
            {children}
        </Fragment>
    )
}
const MemorizedWarningComponent = memo(WarningComponent)
export default MemorizedWarningComponent
