import { useEffect } from 'react'

interface QuizDetails {
  totalTime: number
}

const useTimer = (
  timer: number,
  quizDetails: QuizDetails,
  setEndTime: (time: number) => void,
  setTimer: (time: number) => void,
  setShowTimerModal: (time: boolean) => void,
  showResultModal: boolean
) => {
  useEffect(() => {
    if (timer <= 0) {
      const timeTaken = quizDetails.totalTime
      setEndTime(timeTaken)
      setShowTimerModal(true)
      setTimer(0)
    }
  }, [timer, quizDetails.totalTime, setEndTime, setShowTimerModal, setTimer])

  useEffect(() => {
    if (!showResultModal) {
      const countTimer = setTimeout(() => { //@ts-ignore
        setTimer((prevTimer) => {
          if(prevTimer <= 0){
            clearInterval(countTimer)
            return 0
          }
          return prevTimer - 1
        })
      }, 1000)
      return () => clearTimeout(countTimer)
    }
  }, [timer, setTimer])
  
}
export default useTimer
