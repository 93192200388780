import { PageCenter } from '../../styles/Global'
import LogoIcon from '../ResultScreen/LogoComponent/LogoComponent'

const SplashScreen = () => {
  return (
    <PageCenter justifyCenter>
        <LogoIcon />
    </PageCenter>
  ) 
}

export default SplashScreen
