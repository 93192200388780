import { ReactNode, useState } from 'react'
import { QuestionType, QuizContextTypes, Result, ScreenTypes } from '../types'
import { QuizContext, initialState } from './QuizContext'

type QuizProviderProps = {
  children: ReactNode
}

const QuizProvider = ({ children }: QuizProviderProps) => {
  const [timer, setTime] = useState<number>(0)
  const [endTime, setEndTime] = useState<number>(initialState.endTime)
  const [quizTopic, setQuizTopic] = useState<string>("")
  const [result, setResult] = useState<Result[]>(initialState.result)
  const [totalQuestions, setTotalQuestion] = useState(0)
  
  const [currentScreen, setCurrentScreen] = useState<ScreenTypes>(
    initialState.currentScreen
  )

  const [questions, setQuestions] = useState<QuestionType[] | null>(null)

  const selectQuizTopic = (topic: string) => setQuizTopic(topic)
  const setTimer = (time: number) => setTime(time)
  const totalQuestion = (question: number) => setTotalQuestion(question)

  const quizDetails = {
    totalQuestions,
    totalScore: 100,
    totalTime: timer,
    selectedQuizTopic: quizTopic,
  }
  const quizContextValue: QuizContextTypes = {
    currentScreen,
    setCurrentScreen,
    quizTopic,
    selectQuizTopic,
    questions,
    setQuestions,
    result,
    setResult,
    quizDetails,
    timer,
    setTimer,
    endTime,
    setEndTime,
    totalQuestion
  }

  return <QuizContext.Provider value={quizContextValue}>{children}</QuizContext.Provider>
}

export default QuizProvider
