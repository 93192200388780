import { Dispatch, SetStateAction } from 'react'

export enum ScreenTypes {
  SplashScreen,
  QuizTopicsScreen,
  QuizDetailsScreen,
  QuestionScreen,
  ResultScreen,
  SurveyScreen
}

type Choice = string
type CorrectAnswers = string[]

export type Question = {
  question: string
  choices: Choice[]
  type: 'MCQs' | 'MAQs' | 'boolean'
  correctAnswers: CorrectAnswers
  score: number
  code?: string
  image?: string
}
export interface Result extends Question {
  selectedAnswer: string[]
  isMatch: boolean
}

export type QuizContextTypes = {
  currentScreen: ScreenTypes
  setCurrentScreen: Dispatch<SetStateAction<ScreenTypes>>
  quizTopic: string
  selectQuizTopic: (type: string) => void
  questions: QuestionType[] | null
  setQuestions: (data: QuestionType[]) => void
  result: Result[]
  setResult: Dispatch<SetStateAction<any[]>>
  timer: number
  setTimer: (time: number) => void
  endTime: number
  setEndTime: (type: number) => void
  totalQuestion: (value: number) => void
  quizDetails: {
    totalQuestions: number
    totalScore: number
    totalTime: number
    selectedQuizTopic: string
  }
}

type Subject = {
  id: number
  name: string
}

export type QuestionType = {
  id: number
  question: string
  is_free_option: boolean
  option1: string
  option2: string
  option3: string
  option4: string
  subject: Subject
}
