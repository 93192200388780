import { Fragment } from "react"

interface LogoIconSvg {
    theme: string|undefined
}
export default function LogoIcon({ theme }: LogoIconSvg){
    return(
        <Fragment>
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="200px" height="125px" viewBox="0 0 500 500" href="http://www.w3.org/1999/xlink">
                <g>
                    <path fill={theme === 'dark' ? "#faf9f9" : "#800080"} d="M 199.25,129.25 C 242.25,129.25 285.25,129.25 328.25,129.25C 328.25,149.417 328.25,169.583 328.25,189.75C 286.08,189.907 243.913,189.741 201.75,189.25C 184.369,188.576 168.035,192.325 152.75,200.5C 126.654,218.099 116.072,242.516 121,273.75C 126.732,296.479 140.149,312.896 161.25,323C 170.375,326.724 179.875,328.391 189.75,328C 212.752,328.251 235.752,328.085 258.75,327.5C 260.051,327.032 260.801,326.116 261,324.75C 261.17,307.916 261.086,291.083 260.75,274.25C 282.917,274.25 305.083,274.25 327.25,274.25C 326.96,311.92 326.46,349.586 325.75,387.25C 278.088,387.868 230.421,387.785 182.75,387C 137.089,383.102 101.505,362.353 76,324.75C 59.061,296.117 53.894,265.451 60.5,232.75C 70.836,190.979 95.419,160.896 134.25,142.5C 155.125,133.875 176.792,129.459 199.25,129.25 Z" />
                </g>
                <g>
                    <path fill={theme === 'dark' ? "#faf9f9" : "#800080"} d="M 261.75,203.25 C 283.583,203.25 305.417,203.25 327.25,203.25C 327.25,222.917 327.25,242.583 327.25,262.25C 305.417,262.25 283.583,262.25 261.75,262.25C 261.75,242.583 261.75,222.917 261.75,203.25 Z" />
                </g>
                <g>
                    <path fill={theme === 'dark' ? "#faf9f9" : "#800080"} d="M 374.25,203.25 C 388.605,203.344 402.938,203.927 417.25,205C 473.575,213.997 510.49,245.581 528,299.75C 539.205,350.63 525.62,393.547 487.25,428.5C 464.622,447.556 438.455,457.889 408.75,459.5C 359.758,460.891 310.758,461.141 261.75,460.25C 261.75,440.417 261.75,420.583 261.75,400.75C 308.582,401.281 355.415,401.364 402.25,401C 433.605,398.631 455.022,382.881 466.5,353.75C 475.45,319.423 465.2,292.506 435.75,273C 424.639,266.555 412.639,263.389 399.75,263.5C 378.917,262.667 358.085,262.417 337.25,262.75C 337.25,243.25 337.25,223.75 337.25,204.25C 349.667,204.031 362,203.697 374.25,203.25 Z" />
                </g>
            </svg>
        </Fragment>
    )
}