import { useEffect, useState } from 'react';

export default function usePreferredColorScheme() {
    const getColorScheme = () => {
        if(window){
            return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)')
            .matches ? 'dark' : 'light'
        }
    }

    const [colorScheme, setColorScheme] = useState(getColorScheme)

    useEffect(() => {
        const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)')

        const handleChange = () => {
            setColorScheme(mediaQuery.matches ? 'dark' : 'light')
        };

        mediaQuery.addEventListener('change', handleChange)

        return () => {
            mediaQuery.removeEventListener('change', handleChange)
        }
    }, [])

    return colorScheme
}